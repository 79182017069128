import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';

import './404.scss';

const seo = {
    title: '404: Page Not Found',
    description: `A default page shown when a requested page could not be found.`,
};

const NotFound = () => (
    <Layout pageId="notFound" mainClassName="not-found" title="404: Not Found">
        <SEO
            title={seo.title}
            description={seo.description}
            relativeCanonicalSlug="404"
        />

        <div className="not-found__sad-face">:(</div>
        <h1 className="not-found__title">Page Not Found</h1>
        <p className="not-found__message">
            Oops! The page you requested does not exist.
        </p>
        <Button text="Home" to="" />
    </Layout>
);

export default NotFound;
